/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import Opportunity from './components/opportunity/opportunity';

function Opportunities({ opportunities, activeCategory }) {
  if (opportunities === undefined) {
    return <div />;
  }
  return (
    <div>
      {opportunities
        .filter((opportunity) => opportunity.category.category === activeCategory)
        .map((opportunity, idx) => {
          const link = `https://www.hive.gov/opportunities/detail/${opportunity.id}`;
          return (
            <a key={idx} href={`detail/${opportunity.id}`}>
              <Opportunity opportunity={opportunity} socialLink={link} />
            </a>
          );
        })}
    </div>
  );
}

export default Opportunities;
