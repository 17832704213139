import * as React from 'react';

import { mobileHiddenCategoryDivider } from './category-nav.module.css';

function CategoryNav({ activeCategory, categories, setActiveCategory }) {
  return (
    <div
      className="
            flex
            flex-wrap
            leading-[30px]
            pt-[50px]
            w-full
            lg:pl-0
            mobile:justify-center
            mobile:w-[322px]
            smMobile:w-full
            mobile:mx-auto
        "
    >
      {categories.map(({ category }, idx) => (
        <div
          key={category}
          className="
            text-roboto
            text-24
            mobile:flex
            mobile:text-17
            mobile:w-[107px]
        "
        >
          <button
            type="button"
            className={`
              ${activeCategory === category ? 'text-orange font-bold' : 'text-gray'}
              ${idx === categories.length - 1 ? 'mobile:w-[113px] text-left' : ''}
            `}
            onClick={() => setActiveCategory(category)}
          >
            {category}
          </button>
          {idx < categories.length - 1 ? (
            <span
              className={`
              ${idx === 2 ? mobileHiddenCategoryDivider : ''}
              px-[16px]
              text-gray
              mobile:px-0
              mobile:ml-auto
              mobile:mr-[7px]
            `}
            >
              |
            </span>
          ) : (
            <span />
          )}
        </div>
      ))}
    </div>
  );
}

export default CategoryNav;
