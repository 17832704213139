import { useMemo } from 'react';

export const LEFT_ARROW = '<< Previous Page';
export const RIGHT_ARROW = 'Next Page >>';

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  totalCount,
  pageSize,
  currentPage,
  frameSize = 4,
}) => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize);

    const totalPageNumbers = frameSize;

    if (totalPageNumbers >= totalPageCount) {
      return [LEFT_ARROW, ...range(1, totalPageCount), RIGHT_ARROW];
    }

    const leftIndex = Math.max(currentPage, 1);
    const rightIndex = Math.min(currentPage + frameSize - 1, totalPageCount);

    const numberInFrame = Math.ceil(currentPage / frameSize);

    const shouldShowLeftArrow = leftIndex > 1;
    const shouldShowRightArrow = rightIndex < totalPageCount;

    if (!shouldShowLeftArrow && shouldShowRightArrow) {
      const leftRange = range(1, frameSize);
      return [LEFT_ARROW, ...leftRange, RIGHT_ARROW];
    }

    if (rightIndex < totalPageCount) {
      const rangeStart = numberInFrame * frameSize - frameSize + 1;

      const middleRange = range(rangeStart, rightIndex - 1);
      return [LEFT_ARROW, ...middleRange, RIGHT_ARROW];
    }
    const rangeStart = numberInFrame * frameSize - frameSize + 1;
    const rangeEnd = rangeStart + frameSize - 1;
    if (rangeEnd > totalPageCount) {
      const middleRange = range(rangeStart, rightIndex);

      return [LEFT_ARROW, ...middleRange, RIGHT_ARROW];
    }

    const middleRange = range(rangeStart, rangeEnd);

    return [LEFT_ARROW, ...middleRange, RIGHT_ARROW];
  }, [totalCount, pageSize, currentPage]);

  return paginationRange;
};
