import React, { useEffect, useRef, useState } from 'react';
import { usePagination, RIGHT_ARROW, LEFT_ARROW } from './usePagination';

function Pagination(props) {
  const { onPageChange, totalCount, currentPage, pageSize, frameSize = 4 } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
    frameSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const leftButton = useRef();
  const rightButton = useRef();

  const [leftDisabled, setLeftDisabled] = useState(true);
  const [rightDisabled, setRightDisabled] = useState(false);

  useEffect(() => {
    if (currentPage === 1) {
      setLeftDisabled(true);
      if (!leftButton.current.classList.contains('text-inactive')) {
        leftButton.current.classList.add('text-inactive');
      }
      if (leftButton.current.classList.contains('cursor-pointer')) {
        leftButton.current.classList.remove('cursor-pointer');
        leftButton.current.classList.add('cursor-default');
        leftButton.current.classList.remove('hover:underline');
        leftButton.current.classList.remove('text-neutral-400');
      }
    } else {
      setLeftDisabled(false);
      if (!leftButton.current.classList.contains('cursor-pointer')) {
        leftButton.current.classList.add('cursor-pointer');
        leftButton.current.classList.add('hover:underline');
        leftButton.current.classList.remove('text-inactive');
        leftButton.current.classList.remove('cursor-default');
        leftButton.current.classList.add('text-neutral-400');
      }
    }

    // total number of pages
    const totalPages = Math.ceil(totalCount / pageSize);
    if (totalPages === currentPage) {
      setRightDisabled(true);
      if (!rightButton.current.classList.contains('text-inactive')) {
        rightButton.current.classList.add('text-inactive');
      }
      if (rightButton.current.classList.contains('cursor-pointer')) {
        rightButton.current.classList.remove('cursor-pointer');
        rightButton.current.classList.add('cursor-default');
        rightButton.current.classList.remove('hover:underline');
        rightButton.current.classList.remove('text-neutral-400');
      }
    } else {
      setRightDisabled(false);
      if (!rightButton.current.classList.contains('cursor-pointer')) {
        rightButton.current.classList.add('cursor-pointer');
        rightButton.current.classList.remove('cursor-default');
        rightButton.current.classList.add('hover:underline');
        rightButton.current.classList.remove('text-inactive');
        rightButton.current.classList.add('text-neutral-400');
      }
    }
  }, [currentPage]);

  const onNext = () => {
    if (!rightDisabled) {
      onPageChange(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (!leftDisabled) {
      onPageChange(currentPage - 1);
    }
  };

  const renderNumber = (pageNumber, selectedPage, index) => {
    if (selectedPage === pageNumber) {
      return (
        <div
          className="w-[32px] justify-center text-center  text-[18px] text-lg text-neutral-400 font-bold"
          key={pageNumber}
        >
          {pageNumber}
        </div>
      );
    }
    return (
      <div
        key={pageNumber}
        className="w-[32px] justify-center text-center  text-[18px] text-lg text-neutral-400 font-normal hover:underline cursor-pointer"
        tabIndex={index}
        role="button"
        onKeyDown={() => onPageChange(pageNumber)}
        onClick={() => onPageChange(pageNumber)}
      >
        {pageNumber}
      </div>
    );
  };

  return (
    <div className="max-w-[502px] flex">
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === LEFT_ARROW && index === 0) {
          return (
            /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
            <div
              key={pageNumber}
              disabled={leftDisabled}
              className=" w-[175px] justify-center text-center text-[18px] text-lg"
              tabIndex={index}
              id="left"
              onKeyDown={() => onPrevious()}
              onClick={() => onPrevious()}
              ref={leftButton}
            >
              {LEFT_ARROW}
            </div>
          );
        }
        if (index === 0) {
          return (
            <div className="w-[207px] flex" key={pageNumber}>
              <div className="w-[175px]" />
              {renderNumber(pageNumber, currentPage, index)}
            </div>
          );
        }
        if (pageNumber === RIGHT_ARROW) {
          return (
            /* eslint-disable-next-line jsx-a11y/no-static-element-interactions */
            <div
              key="right"
              className="w-[150px] justify-center text-center text-[18px] text-lg text-neutral-400 "
              disabled={leftDisabled}
              tabIndex={index}
              onKeyDown={() => onNext()}
              onClick={() => onNext()}
              ref={rightButton}
            >
              {RIGHT_ARROW}
            </div>
          );
        }

        return renderNumber(pageNumber, currentPage, index);
      })}
    </div>
  );
}

export default Pagination;
