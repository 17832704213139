import * as React from 'react';
import { useState, useEffect } from 'react';
import CategoryNav from './components/category-nav/category-nav';
import Opportunities from '../../../components/opportunities/opportunities';
import Pagination from '../../../components/pagination/pagination';

function Section1({ pageData, oppsPerPage = 5 }) {
  const [state, setState] = React.useState({
    activeCategory: pageData.defaultCategory.category,
  });

  const [oppsPages, setOppsPages] = useState([]);
  const [oppsIndex, setOppsIndex] = useState(1);
  const [oppsView, setOppsView] = useState([]);
  const [viewData, setViewData] = useState([]);

  const setActiveCategory = (activeCategory) => setState({ activeCategory });

  useEffect(() => {
    const sectionData = pageData.opportunities
      .filter(
        (opportunity) => opportunity.category.category === state.activeCategory
      )
      .map((opportunity) => {
        return opportunity;
      });
    setViewData(sectionData);
  }, [state.activeCategory]);

  useEffect(() => {
    if (viewData.length > 0) {
      /* eslint-disable no-param-reassign */
      const result = viewData.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / oppsPerPage);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
      setOppsPages(result);
      setOppsIndex(1);
    } else {
      setOppsPages([]);
    }
  }, [viewData]);

  useEffect(() => {
    setOppsView(oppsPages[oppsIndex - 1]);
  }, [oppsIndex, oppsPages]);

  const onPageChange = (pageNumber) => {
    setOppsIndex(pageNumber);
  };

  return (
    <section className="w-full mobile:px-[15px]">
      <div
        className="
                xl:w-[1199px]
                lg:w-[920px]
                mobile:w-[390px]
                smMobile:w-full
                mx-auto
            "
      >
        <CategoryNav
          categories={pageData.categories}
          setActiveCategory={setActiveCategory}
          activeCategory={state.activeCategory}
        />
      </div>
      <Opportunities
        opportunities={oppsView}
        activeCategory={state.activeCategory}
      />
      {oppsPages.length < 2 && (
        <div className="xl:mb-[90px] lg:mb-[80px] mobile:mb-[80px]" />
      )}
      {oppsPages.length > 1 && (
        <div className="flex flex-col justify-center items-center mt-20 mb-16">
          <Pagination
            onPageChange={(index) => onPageChange(index)}
            totalCount={pageData.opportunities.length}
            pageSize={oppsPerPage}
            currentPage={oppsIndex}
          />
        </div>
      )}
    </section>
  );
}

export default Section1;
