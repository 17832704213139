import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout/layout';

import science from '../images/headerOpportunities.svg';
import Section0 from '../page-components/opportunities/section-0/section-0';
import Section1 from '../page-components/opportunities/section-1/section-1';

function OpportunitiesPage({ data, location }) {
  const pageData = data.contentfulOpportunitiesPage;
  const headerContent = {
    heading: 'Opportunities',
    subheading:
      'Every year, billions of dollars in health investments is available for innovators in the Federal Health IT space. This is where you can join learn more, collaborate, and join the conversation about these health innovations and investments.',
    image: science,
    alt: 'Opportunities Abound',
  };

  return (
    <Layout
      title="Opportunities | HIVE"
      headerContent={headerContent}
      location={location}
    >
      <Section0 pageData={pageData} />
      <Section1 pageData={pageData} />
    </Layout>
  );
}

export default OpportunitiesPage;

export const pageQuery = graphql`
  {
    contentfulOpportunitiesPage {
      opportunities {
        id
        title
        body {
          childMarkdownRemark {
            html
          }
          body
        }
        solicitationNumber
        value
        type
        release
        category {
          category
        }
      }
      categories {
        category
      }
      defaultCategory {
        category
      }
      roadMapHeader
      roadMap {
        file {
          url
        }
      }
    }
  }
`;
